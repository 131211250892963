import React from 'react'
import { graphql } from 'gatsby'
import { NotFoundPageTemplate } from 'precision/pages/error'

const NotFoundPage = ({ data: { notFoundJson } }) => {
  return <NotFoundPageTemplate {...notFoundJson} />
}

export default NotFoundPage

export const NotFoundPageQuery = graphql`
  query NotFoundPage {
    notFoundJson {
      heading
      text
    }
  }
`
